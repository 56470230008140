<template>
  <UserForm backPath="/users" />
</template>

<script>
import UserForm from '../../components/users/UserForm';

export default {
  components: { UserForm },
};
</script>
